import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer>
      (c) copyright 2024 stylematch <br/>
      v0.28
    </footer>
  );
};

export default Footer;
